<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="agGrid-page container">
      <base-header :old_kpi="old_kpi" />
      <b-overlay
        :show="overlay"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <base-ag-grid
          :columnDefs="columnDefs"
          :datasource="datasource"
          :cellValueChanged="cellValueChanged"
          :customRowId="(params) => { return params.data.key }"
          :enableRangeSelection="true"
          :getContextMenuItems="getContextMenuItems"
          :rowClassRules="rowClassRules"
        />
        <template #overlay>
          <div class="text-center">
            <b-icon class="mb-4" variant="primary" icon="stopwatch" font-scale="5" animation="cylon"></b-icon>
            <p id="cancel-label">Please wait... Data is recalculated in real time</p>
          </div>
        </template>
      </b-overlay>
      <div class="actions" style="display: flex; justify-content: space-between;">
        <div>
          <h6>Business model data</h6>
          <b-overlay
            :show="overlayBusiness"
            spinner-variant="primary"
            spinner-type="grow"
            spinner-small
            rounded="sm"
          >
            <ag-grid-vue
              class="ag-theme-alpine"
              style="width: 530px; height: 161px"
              headerHeight="32"
              rowHeight="32"
              :columnDefs="columnDefsBusiness"
              :rowData="rowDataBusiness"
              :suppressContextMenu="true"
            >
            </ag-grid-vue>
          </b-overlay>
        </div>
        <b-btn
          variant="primary"
          :class="['border-0 border-bottom rounded-0 mb-3', Object.keys(changes).length === 0 ? 'disabled' : '']"
          @click="saveChanges()"
        >Save Changes</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import KpiCustomDataPopup from '@/agGridV2/components/kpi-custom-data-popup.component.vue'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
// API Init
import LeadCenterNavigation from '@/components/LeadCenterNavigation.vue'
import StatusBar from '@/agGridV2/renderers/status-bar.renderer.vue'
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import 'ag-grid-enterprise/styles/ag-grid.css'
import 'ag-grid-enterprise/styles/ag-theme-alpine.css'
import { AgGridVue } from 'ag-grid-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    AgGridVue,
    LeadCenterNavigation,
    // eslint-disable-next-line vue/no-unused-components
    StatusBar,
    BaseAgGrid,
    BaseHeader
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      gridApi: null,
      cellValueChanged: null,
      overlay: false,
      overlayBusiness: false,
      changes: {},
      rowClassRules: {
        'row-group-total': (params) => {
          return params?.data?.group_total
        },
        'row-group-auto': (params) => {
          return !params?.data?.auto && !params?.data?.group_total && !params?.data?.editable
        }
      },
      columnDefsBusiness: [
        {
          field: 'name',
          headerName: 'Name',
          width: '250px',
          suppressHeaderMenuButton: true,
          sortable: false
        },
        {
          field: 'in_settings_label',
          headerName: 'In Settings',
          flex: 1,
          suppressHeaderMenuButton: true,
          sortable: false
        },
        {
          field: 'calculated_label',
          headerName: 'Calculated',
          flex: 1,
          suppressHeaderMenuButton: true,
          sortable: false
        },
        {
          field: 'settings',
          headerName: 'Settings',
          flex: 1,
          suppressHeaderMenuButton: true,
          sortable: false,
          cellRenderer: (params) => {
            const btn = document.createElement('div')
            btn.textContent = 'Save'
            btn.style.width = 'calc(100% + 16px)'
            btn.style.cursor = 'pointer'
            btn.style.margin = '-0px -9px 0 -9px'
            btn.style.padding = '0 9px'
            btn.addEventListener('mouseover', () => {
              btn.style.backgroundColor = '#7987FF'
              btn.style.color = '#ffffff'
            })
            btn.addEventListener('mouseout', () => {
              btn.style.backgroundColor = 'transparent'
              btn.style.color = '#000000'
            })
            btn.addEventListener('click', () => {
              this.$dialog
                .confirm(`<img style="width: 100%; margin-bottom: 20px;" src="/uncle_sam.webp" />Are you sure??? This will permanently change the settings`, {
                  html: true,
                  cancelText: 'Close',
                  okText: 'I\'m sure',
                  clicksCount: 3,
                  backdropClose: true,
                  type: 'soft'
                })
                .then(async(dialog) => {
                  await MtApi.updatePlatform(this.selectedPlatform._id, {
                    event: 'update_platform',
                    field: {
                      name: params.data.field_name_in_db,
                      value: params.data.calculated
                    }
                  })
                  this.gridApi.refreshServerSide({ purge: true })
                })
            })
            return btn
          }
        }
      ],
      getContextMenuItems: (params) => {
        const result = ['copy']
        const column = params.column.colId
        result.push({
          name: 'Set custom data',
          action: () => {
            this.$modal.show(
              KpiCustomDataPopup,
              {
                column,
                rowKey: params.node.data.key,
                customKpi: params.node.data[column].custom_kpi,
                gridApi: this.gridApi
              }, { width: '30%' }
            )
          }
        })
        return result
      },
      rowDataBusiness: [],
      old_kpi: null
    }
  },
  computed: {
    selectedPlatform: {
      get() {
        return this.getSelectedPlatform()
      }
    }
  },
  watch: {
    selectedPlatform() {
      (async () => {
        await this.init()
      })()
    }
  },
  mounted() {
    (async () => {
      await this.init()
    })()
  },
  methods: {
    ...mapGetters([
      'getSelectedPlatform'
    ]),
    async saveChanges() {
      this.overlay = true
      const data = {
        event: 'update_kpi_custom',
        field: {
          platform: this.selectedPlatform._id,
          changes: this.changes
        }
      }
      this.changes = {}
      await MtApi.updatePlatform(this.selectedPlatform._id, data)
      this.gridApi.refreshServerSide({ purge: false })
    },
    async init() {
      if (this.selectedPlatform._id === 'all') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        await this.$router.push({ name: 'lead-center-activities' })
        return false
      }
      // const months1 = await MtApi.getMonthForKpi(this.selectedPlatform._id)
      // const firstMonthProject = await MtApi.getFirstMonthProject({ platform_id: this.selectedPlatform._id })
      const platform = await MtApi.getPlatform(this.selectedPlatform._id, 'old_kpi')
      if (platform.old_kpi) {
        this.old_kpi = platform.old_kpi
      }
      const dataObjects = await MtApi.getMonthForKpi(this.selectedPlatform._id)
      const yearColumns = []
      const months = SimpleHelper.monthsLabels
      for (const object of dataObjects) {
        yearColumns.push({
          field: `${object.month + '_' + object.year}`,
          headerName: months[object.month] + ' ' + object.year,
          cellEditor: 'agNumberCellEditor',
          filter: false,
          sortable: false,
          valueSetter: (params) => {
            params.data[params.colDef.field].value = params.newValue ? params.newValue.toString().replace(/\D/g, '') : params.newValue
            return true
          },
          valueFormatter: (params) => {
            if (params.data.format === 'price') {
              return SimpleHelper.priceFormatter(params.data[params.colDef.field]?.value)
            }
            if (params.data.format === 'percent') {
              return SimpleHelper.percentFormatter(params.data[params.colDef.field]?.value)
            }
            return params.data[params.colDef.field]?.value
          },
          valueGetter: (params) => {
            return params.data[params.colDef.field]?.value
          },
          editable: (params) => {
            return params.data.editable
          },
          cellStyle: (params) => {
            if (params.data[params.colDef.field]?.custom_kpi && !params.data?.editable) {
              return { color: '#000000', backgroundColor: '#ffff00' }
            }
            return null
          },
          width: '125px'
        })
      }
      this.columnDefs = [
        {
          field: 'label',
          headerName: 'Label',
          filter: false,
          sortable: false,
          pinned: true,
          cellRenderer: (params) => {
            if (params.data.label_strong) {
              return `<strong>${params.data.label}</strong>`
            }
            return params.data.label
          }
        },
        ...yearColumns
      ]
      this.datasource = {
        getRows: (params) => {
          this.overlay = true
          this.overlayBusiness = true
          this.gridApi = params.api
          params.request['search'] = this.$store.getters.getSearchQuery(this.$route.name)
          params.request['platform'] = this.$store.getters.getSelectedPlatform
          MtApi.agGridKpi(params.request).then((response) => {
            this.$store.dispatch('setAgGridRowsCount', { page: this.$route.name, query: response.count })
            this.$emitter.emit('set_agGrid_rows_count', {})
            params.success({ rowData: response.rows, rowCount: response.count })
            this.overlay = false
            this.overlayBusiness = false
            this.rowDataBusiness = response.business_data
          })
        }
      }
      this.cellValueChanged = async (params) => {
        if (!this.changes[params.data.key]) {
          this.$set(this.changes, params.data.key, {})
        }
        if (!this.changes[params.data.key][params.colDef.field]) {
          this.$set(this.changes[params.data.key], params.colDef.field, null)
        }
        this.changes[params.data.key][params.colDef.field] = {
          value: params.value
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  button {
    height: 44px;
  }
}
.average-data {
  ul {
    padding-left: 25px;
  }

}
</style>
