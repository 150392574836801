import { render, staticRenderFns } from "./kpi-custom-data-popup.component.vue?vue&type=template&id=030cf027&scoped=true&"
import script from "./kpi-custom-data-popup.component.vue?vue&type=script&lang=js&"
export * from "./kpi-custom-data-popup.component.vue?vue&type=script&lang=js&"
import style0 from "./kpi-custom-data-popup.component.vue?vue&type=style&index=0&id=030cf027&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030cf027",
  null
  
)

export default component.exports